<template>
  <div class="event-file-upload">
    <FileMultiUpload
      :images="form.images"
      :loading="form.$busy || loading"
      :min-image="minImage"
      :for-update="forUpdate"
      :error="error"
      :disableCropping="true"
      class="event-details__uploader"
      @input="toUpload"
      @delete="toDelete"
      :showUploadImageBtn="showUploadImageBtn"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Event: File Upload
 * ==================================================================================
 **/

import { mapState, mapActions } from 'vuex'
import FileMultiUpload from '@/components/fields/FileMultiUpload'
import Form from '@/utils/form'
import findIndex from 'lodash/findIndex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {
    FileMultiUpload,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    minImage: {
      type: Number,
      default: 1,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    forUpdate: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Array],
      default: null,
    },

    showUploadImageBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: new Form({
        images: [],
      }),
    }
  },

  computed: {
    ...mapState({
      event: (state) => state.event.eventDetails,
    }),
  },

  watch: {
    event(newValue, oldValue) {
      this.initForm()
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      uploadImages: 'event/uploadEventImage',
      deleteImage: 'event/deleteEventImage',
    }),

    initForm() {
      if (this.event) {
        this.form.images = this.event.images || []
      }
    },

    async toUpload(images) {
      if (!this.forUpdate) {
        this.form.images = [...this.form.images, ...images]
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.uploadImages(this.getUploadFormData(images))
          .then(() => {
            this.initForm()
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }

      this.$emit('updated')
    },

    async toDelete(image) {
      if (!this.forUpdate) {
        this.removeImage(image)
      } else {
        if (this.form.$busy) return

        this.form.$busy = true
        await this.deleteImage(this.getDeleteFormData(image))
          .then(() => {
            this.removeImage(image)
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.form.$busy = false
          })
      }
    },

    removeImage(image) {
      const index = findIndex(this.form.images, image)
      if (index >= 0) {
        this.form.images.splice(index, 1)
        this.showSnackbar(
          `Image ${this.forUpdate ? 'deleted successfully' : 'removed'}!`
        )
      }
    },

    getUploadFormData(images) {
      let form = new FormData()
      form.append('id', this.event.id)
      form.append('image_name', 'images')
      this.getImages(form, images)

      return form
    },

    getDeleteFormData(image) {
      return {
        id: image.id,
        image_name: 'images',
      }
    },

    getImages(form, images = null) {
      const forUpload = images || this.form.images
      if (form) {
        for (let i = 0; i < forUpload.length; i++) {
          form.append('images[]', forUpload[i].file)
        }
      }
    },

    hasData() {
      return this.form.images.length > 0
    },
  },
}
</script>
