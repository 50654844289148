<template>
  <div class="datetime-picker">
    <datetime
      ref="dateField"
      class="datetime-picker__date"
      v-model="date"
      :type="type"
      :value-zone="valueZone"
      @input="selectDate"
    >
      <v-text-field
        :value="parsedDate"
        class="datetime-picker__input"
        slot="before"
        readonly
        @click="open"
        v-bind="$attrs"
      />
    </datetime>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Date/Time Picker
 * https://github.com/mariomka/vue-datetime
 * ==================================================================================
 **/
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'
import { dateFormat, convertToDayjs } from '@/utils/date'
import { capitalize } from '@/utils/helpers'
import SnackbarMixin from '@/utils/mixins/Snackbar'

export default {
  name: 'Datepicker',
  mixins: [SnackbarMixin],

  props: {
    value: {
      type: [String, Boolean, Object],
      default: null,
    },

    type: {
      type: String,
      default: 'datetime',
      validator: (value) => ['date', 'time', 'datetime'].includes(value),
    },

    dateFormat: {
      type: String,
      default: DATETIME_FORMAT.dateFormat,
    },

    timeFormat: {
      type: String,
      default: DATETIME_FORMAT.timeFormat,
    },

    lessThan: {
      type: String,
      default: null,
    },

    greaterThan: {
      type: String,
      default: null,
    },

    /**
     * vue-datetime options
     */
    valueZone: {
      type: String,
      default: 'UTC',
    },
  },

  data() {
    return {
      parsedDate: null,
      date: null,

      error: null,
    }
  },

  computed: {
    typeLabel() {
      switch (this.type) {
        case 'datetime':
          return 'Date & Time'
        default:
          return capitalize(this.type)
      }
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init()
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.date = this.value
      this.parsedDate = this.getParseDate(this.value)

      this.error = null
    },

    open() {
      if (this.$refs.dateField) {
        this.$refs.dateField.isOpen = true
      }
    },

    selectDate() {
      if (this.validate()) {
        this.$emit('input', this.date)
      } else {
        this.showSnackbar(this.error, false)
        this.$emit('input', this.value)
      }
    },

    validate() {
      this.error = null

      if (this.lessThan && !convertToDayjs(this.lessThan).isAfter(this.date)) {
        this.error = `${this.typeLabel} must be less than ${this.getParseDate(
          this.lessThan
        )}`
      }

      if (
        this.greaterThan &&
        !convertToDayjs(this.greaterThan).isBefore(this.date)
      ) {
        this.error = `${
          this.typeLabel
        } must be greater than ${this.getParseDate(this.greaterThan)}`
      }

      return !this.error
    },

    getParseDate(date) {
      switch (this.type) {
        case 'date':
          return dateFormat(date, this.dateFormat)
        case 'time':
          return dateFormat(date, this.timeFormat)
        case 'datetime':
        default:
          return dateFormat(date, `${this.dateFormat} ${this.timeFormat}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.datetime-picker {
  ::v-deep .datetime-picker__date {
    .vdatetime-input {
      display: none;
    }
  }

  ::v-deep .datetime-picker__input {
    input {
      cursor: pointer !important;
    }

    input:disabled {
      cursor: not-allowed !important;
    }
  }
}
</style>
