<template>
  <div class="event-date-field">
    <v-row dense>
      <v-col cols="12" md="6">
        <DatetimePicker
          v-model="eventDate"
          :flat="flat"
          :solo="solo"
          :loading="loading"
          :disabled="disabled"
          type="date"
          placeholder="Date"
          hide-details
        />
      </v-col>
      <v-col cols="6" md="3">
        <DatetimePicker
          v-model="form.datetime_start"
          :flat="flat"
          :solo="solo"
          :loading="loading"
          :disabled="disabled || !hasDate"
          type="time"
          placeholder="Start"
          hide-details
        />
      </v-col>
      <v-col cols="6" md="3">
        <DatetimePicker
          v-model="form.datetime_end"
          :flat="flat"
          :solo="solo"
          :loading="loading"
          :disabled="disabled || !hasDate"
          type="time"
          placeholder="End"
          hide-details
        />
      </v-col>
    </v-row>

    <div
      class="event-date-field__error"
      :class="{
        'event-date-field__error--sentence': errorDisplay === 'sentence',
      }"
    >
      <ErrorMessage :error="error" :display="errorDisplay" />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Event Date field
 * ==================================================================================
 **/
import Form from '@/utils/form'
import { convertToDayjs, isSameDate } from '@/utils/date'
import DatetimePicker from '@/components/fields/DatetimePicker'
import dayjs from 'dayjs'
import ErrorMessage from '@/components/fields/ErrorMessage'

const DATE_FIELDS = ['datetime_start', 'datetime_end']

export default {
  name: 'EventDateField',
  props: {
    form: {
      type: [Object, Form],
      validator(obj) {
        if (typeof obj === 'object' && obj !== null) {
          for (const str of DATE_FIELDS) {
            if (!obj.hasOwnProperty(str)) {
              return false
            }
          }

          return true
        }

        return false
      },
    },

    flat: {
      type: Boolean,
      default: false,
    },

    solo: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatetimePicker,
    ErrorMessage,
  },

  data() {
    return {
      eventDate: null,
    }
  },

  computed: {
    error() {
      const startError = this.form.$getError('datetime_start') || []
      const endError = this.form.$getError('datetime_end') || []

      return [...startError, ...endError]
    },

    errorDisplay() {
      return this.error.length > 1 ? 'list' : 'sentence'
    },

    hasDate() {
      return !!this.eventDate
    },
  },

  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!this.eventDate) {
          this.init()
        }

        if (
          (newValue.datetime_start &&
            !isSameDate(newValue.datetime_start, this.eventDate)) ||
          (newValue.datetime_end &&
            !isSameDate(newValue.datetime_end, this.eventDate))
        ) {
          this.updateDate()
        }
      },
      deep: true,
    },

    eventDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateDate()
      }
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      if (!this.eventDate && this.form && this.form.datetime_start) {
        this.eventDate = this.form.datetime_start
      }
    },

    updateDate() {
      if (this.eventDate) {
        if (this.form.datetime_start)
          this.form.datetime_start = this.getChangedTime(
            this.form.datetime_start
          )

        if (this.form.datetime_end)
          this.form.datetime_end = this.getChangedTime(this.form.datetime_end)
      }
    },

    getChangedTime(date) {
      if (date) {
        const eventDate = dayjs(this.eventDate)

        return new Date(
          convertToDayjs(date)
            .year(eventDate.year())
            .month(eventDate.month())
            .date(eventDate.date())
        ).toISOString()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.event-date-field {
  ::v-deep input:disabled::placeholder {
    color: rgba(0, 0, 0, 0.1) !important;
  }

  &__error {
    min-width: 25px;
    margin: 5px 0 10px 0;
  }

  &__error--sentence {
    padding-left: 12px;
  }
}
</style>
