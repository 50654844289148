<template>
  <div class="event-form">
    <div ref="form" class="event-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.title"
            placeholder="Title"
            :error-messages="form.$getError('title')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Category</label>
          <SelectCategory
            flat
            solo
            required
            class="mt-2"
            v-model="form.category_id"
            :type="type"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('category_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <v-text-field
            v-if="showPriceField"
            v-model="form.price"
            flat
            solo
            required
            class="mt-2"
            placeholder="Price"
            type="Number"
            :error-messages="form.$getError('price')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">RSVP Limit</label>
          <v-text-field
            v-model="form.rsvp_limit"
            flat
            solo
            required
            hide-details="auto"
            class="mt-2"
            placeholder="0"
            type="Number"
            :error-messages="form.$getError('rsvp_limit')"
            :loading="form.$busy"
            :disabled="form.$busy"
            >0</v-text-field
          >
          <p class="hint">Leave as 0 (zero) if there is no RSVP limit</p>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Event Date</label>
          <EventDateField
            flat
            solo
            required
            class="mt-2"
            placeholder="Event Date"
            :form="form"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Location</label>
          <v-text-field
            v-model="form.location"
            flat
            solo
            required
            class="mt-2"
            placeholder="Location"
            :error-messages="form.$getError('location')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Location Address</label>
          <v-text-field
            v-model="form.location_address"
            flat
            solo
            required
            class="mt-2"
            placeholder="Location Address"
            :error-messages="form.$getError('location_address')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Description</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="Description"
            hide-details="auto"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <v-row dense class="mt-5">
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_featured"
                hide-details="auto"
                label="Set as featured"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.hide_rsvp"
                hide-details="auto"
                label="Hide RSVP"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Banner</label>
          <EventBannerUpload
            ref="bannerUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('banner')"
            :for-update="isUpdate"
            @updated="form.$clearError('banner')"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 700px x 390px</p>

          <label class="text-field-label">Images</label>
          <EventFileUpload
            ref="imagesUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('images')"
            :for-update="isUpdate"
            @updated="form.$clearError('images')"
            :showUploadImageBtn="hasUpdatePermission"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              @click="submit"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Event Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import EventDateField from '@/views/Home/Event/Events/components/EventDateField'
import EventBannerUpload from '@/views/Home/Event/Events/components/EventBannerUpload'
import EventFileUpload from '@/views/Home/Event/Events/components/EventFileUpload'
import SelectBuilding from '@/components/fields/SelectBuilding'
import SelectCategory from '@/components/fields/SelectCategory'
import CATEGORY_TYPE from '@/utils/enums/CategoryType'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    EventDateField,
    EventBannerUpload,
    EventFileUpload,
    SelectBuilding,
    SelectCategory,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    event: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        category_id: '',
        title: '',
        price: 0,
        datetime_start: '',
        datetime_end: '',
        location: '',
        location_address: '',
        description: '',
        is_featured: '',
        rsvp_limit: 0,
        hide_rsvp: '',

        banner: null,
        images: [],
      }),
    }
  },

  computed: {
    ...mapState({
      createdEvent: (state) => state.event.eventDetails,
      permissions: (state) => state.auth.permissions,
    }),

    type() {
      return CATEGORY_TYPE.event
    },

    header() {
      return this.isUpdate ? 'Event Information' : 'Create Event'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateEvent : this.createEvent
    },

    isUpdate() {
      return !!this.event
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.EVENTS_UPDATE], this.permissions)
    },
  },

  watch: {
    event(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createEvent: 'event/createEvent',
      updateEvent: 'event/updateEvent',
    }),

    initForm() {
      if (this.event) {
        this.form.building_id = this.event.building_id
        this.form.category_id = this.event.category_id
        this.form.title = this.event.title
        this.form.price = this.event.price
        this.form.datetime_start = this.event.datetime_start
        this.form.datetime_end = this.event.datetime_end
        this.form.location = this.event.location
        this.form.location_address = this.event.location_address
        this.form.description = this.event.description
        this.form.is_featured = this.event.is_featured
        this.form.rsvp_limit = this.event.rsvp_limit
        this.form.hide_rsvp = this.event.hide_rsvp
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Event details successfully updated!')
          } else {
            this.showSnackbar('Event successfully created!')
            this.$router.push({
              name: 'event.dashboard',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      if (!this.$refs.bannerUploader.hasData()) {
        this.form.$setError('banner', 'Banner is required')
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      delete form.banner
      delete form.images

      if (this.isUpdate) {
        form.id = this.event.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          formData.append(field, form[field])
        }

        formData.append('banner', this.$refs.bannerUploader.getImage())
        this.$refs.imagesUploader.getImages(formData)

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.event-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
