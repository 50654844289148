<template>
  <div class="create-event">
    <EventAppbar />
    <div class="ma-4">
      <EventForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Event
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import EventAppbar from '@/views/Home/Event/Events/components/EventAppbar'
import EventForm from '@/views/Home/Event/Events/components/EventForm'

export default {
  components: {
    EventAppbar,
    EventForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'event/clearEventDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-event {
  //
}
</style>
